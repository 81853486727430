<template>
  <b-overlay :show="loading || !product">
    <section v-if="product">
      <div v-if="specification === 'nss'">
        <NSSExxtra :product="product" />
      </div>
      <div v-else>
        <GeneralLoans :product="product" />
      </div>
    </section>
  </b-overlay>
</template>

<script>
import { get } from "lodash";
import { BOverlay } from "bootstrap-vue";
import NSSExxtra from "@/pages/client/loans/new/nss-exxtra/index.vue";
import GeneralLoans from "@/pages/client/loans/new/general-loan/index.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  name: "Index",
  components: { NSSExxtra, GeneralLoans, BOverlay },
  data() {
    return {
      specification: "",
      loading: false,
      product: null
    }
  },
  computed: {
    productId() {
      const { loan_product_id } = this.$route.params;
      return loan_product_id;
    },
  },
  async created() {
    this.canRequestLoanProduct()
        .then(() => this.fetchLoanProduct())
  },
  methods: {
    async fetchLoanProduct() {
      try {
        this.loading = true

        const response = await this.useJwt().clientService.fetchLoanProduct(this.productId);

        this.product = this.getValueFromSource(response, "data.data");
        this.specification = this.getValueFromSource(this.product, 'specification')
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        this.errorMessage = error_message;

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false
      }
    },
    async canRequestLoanProduct() {
      try {
        this.loading = true;

        const response = await this.useJwt().clientService.canRequestLoanProduct(this.productId);
        const { can_request_loan } = response.data.data

        if (!can_request_loan) {
          this.$router.push({
            name: "client-new-loan-overview",
            params: { loan_product_id: this.productId }
          }).catch(() => {});

          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Kindly complete the form below to proceed.",
              icon: "AlertCircleIcon",
              variant: "warning",
            },
          });
        }
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message;
        console.log("error_message", error_message);
        this.errorMessage = error_message;

        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error!",
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: error_message,
          },
        });
      } finally {
        this.loading = false;
      }
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-slider.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import "~@core/scss/base/pages/app-invoice.scss";
@import '~@core/scss/base/components/variables-dark';

.new-loan-request-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }

  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    color: #FFFFFF;
  }
}

.call-to-action-button {
  background-color: #ffffff;
  border-color: $secondary;
}

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  transition: .35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;
    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;
    .row .border {
      background-color: $theme-dark-card-bg;
    }

  }
}

.wrap {
  border-left: 1px dashed #ddd;
  border-right: 1px dashed #ddd;
  padding: 10px 0;
}

.w-20 {
  width: 150px;
}

.new-loan-file-picker {
  .custom-file-label::after {
    background-color: #459042;
    color: whitesmoke;
    padding-left: 40px;
    padding-right: 40px;
  }
}

.number-icon {
  background-color: #b6b6c6;
  color: #040405;
  padding: 7px;
  cursor: pointer;
  border-radius: 50%;
}

.number-icon:hover {
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.number-icon:active {
  --tw-shadow: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.disabled {
  cursor: not-allowed;
}

[dir] .custom-control-input:checked~.custom-control-label::before,
[dir] .custom-control-input:active~.custom-control-label::before,
[dir] .custom-control-input:focus~.custom-control-label::before {
  border-color: $secondary;
  background-color: $secondary;
}
</style>

