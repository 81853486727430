<template>
<section v-if="product" class="new-loan-request-wrapper">
  <validation-observer ref="applyLoanForm">
    <b-form class="auth-register-form mt-2 pt-2" @submit.prevent="requestLoan">
      <b-row class="new-loan-request">
        <b-col cols="12" xl="8" md="8">
          <div class="invoice-preview-card">
            <div v-if="errorMessage" class="invoice-padding pt-2">
              <b-alert variant="danger" :show="true">
                <div class="alert-body font-small-2">
                  <b-card-text class="">
                    {{ errorMessage }}
                  </b-card-text>
                </div>
              </b-alert>
            </div>

            <b-card>
              <div class="invoice-padding pt-1">
                <div class="mb-2 text-center">
                  <h4 class="m-0 p-0">
                    Loan Reason
                  </h4>
                  <small>Why are you requesting for this loan </small>
                </div>
                <b-form-group>
                  <validation-provider #default="{ errors }" name="Reason For Loan" rules="required">
                    <b-form-textarea id="loan-reason" v-model="newLoan.reason_for_loan" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
            </b-card>

            <b-card>
              <div class="invoice-padding pt-1">
                <div class="mb-2 text-center">
                  <h4 class="m-0 p-0">
                    Loan Duration
                  </h4>
                  <small> Choose a tenure to select how long do you need to complete full repayment of this loan</small>
                </div>
                <div class="text-center">
                  <div class="d-flex justify-content-center align-items-center">
                    <h1 class="display-4 font-weight-bold mr-50">
                      {{ term }}
                    </h1>
                    <h1>months</h1>
                  </div>

                </div>
                <div class="d-flex align-items-center justify-content-center overflow-x-scroll mt-2">

                  <div v-for="(loan_term, i) in activeLoanTerms" :key="i" class="mr-1">
                    <div>
                      <b-form-radio :key="i" v-model="newLoan.term_id" :value="loan_term.term_id" name="loan-term" :button="true" :button-variant="loan_term.term_id === newLoan.term_id ? 'primary rounded-pill' : 'outline-primary rounded-pill'">
                        <span class="d-inline-block px-2">{{ loan_term.term_data.title }}</span>
                      </b-form-radio>
                      <small class="text-primary d-block text-center">up to Gh¢{{ formatMoney(getLoanTermMaxAmount(loan_term), 0) }}</small>
                    </div>
                  </div>
                </div>
              </div>
            </b-card>

            <b-card>
              <div class="invoice-padding pt-0">
                <div class="mb-2 text-center">
                  <h4 class="m-0 p-0">
                    Select Loan Amount
                  </h4>
                  <small>
                    Move the slider or enter an amount to select a loan amount
                    <br />
                    This amount is before interest and charges
                  </small>
                </div>
                <b-form-group label-for="loan_amount" label-cols-sm="2" label-cols-lg="2" content-col-sm content-cols-lg-10 label="Loan Amount">
                  <validation-provider #default="{ errors }" name="Loan Amount" :rules="`required|min_value:${minLoanAmountForThisProduct}|max_value:${maxLoanAmountForThisProduct}`">
                    <b-form-input id="loan_amount" v-model="newLoan.amount" :state="errors.length > 0 ? false : null" size="sm" class="w-20" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <div v-if="showCleave" class="d-flex mt-3 mb-2" style="padding-bottom: 3px;">
                  <feather-icon icon="MinusIcon" size="36" :class="{ 'disabled': +newLoan.amount === +minLoanAmountForThisProduct }" class="number-icon" @click="decreaseAmount" />
                  <cleave id="number" v-model="amountInput" class="form-control w-100 text-lg" style="margin-left: 5px; margin-right: 5px; font-size: 20px" raw :options="amountInputCleaveOptions" />
                  <feather-icon icon="PlusIcon" size="36" :class="{ 'disabled': +newLoan.amount === +maxLoanAmountForThisProduct }" class="number-icon" @click="increaseAmount" />
                </div>

                <div v-else class="wrap">
                  <vue-slider v-model="newLoan.amount" :contained="true" direction="ltr" class="mb-1" v-bind="amountSliderOptions" :hide-label="hideSliderLabel" />
                </div>

              </div>
            </b-card>

            <b-card v-if="customFieldsWithActiveQuestions.length">
              <h4 class="mb-2">
                Additional Questions
              </h4>
              <div v-for="(custom_field, index) in customFieldsWithActiveQuestions" :key="index">
                <h5 class="my-2 text-muted">
                  {{ custom_field.title }}
                </h5>
                <validation-observer ref="customFieldsQuestionsForm">
                  <b-form>
                    <b-form-group v-for="(field, index2) in getActiveFields(custom_field)" :key="index2" :label-for="`cfield-${index2}`" label-cols-sm="4" label-cols-lg="4" content-col-sm content-cols-lg-7>
                      <template #label>
                        <span>{{ field.title }} <sup v-if="field.required_field" style="color: tomato">*</sup></span>
                      </template>
                      <validation-provider #default="{ errors }" :name="field.title" :rules="field.required_field ? 'required' : ''">
                        <b-form-file v-if="field.field_type === 'file'" accept="text/csv,.pdf,.jpg,.jpeg,.png" no-drop :value="newLoan[field._id]" @input="setCustomFieldAttachment(field, $event)" />
                        <b-form-textarea v-if="field.field_type === 'text-area'" :id="`cfield-${index2}`" :value="getFieldResponse(field)" :placeholder="field.placeholder" @input="setFieldResponse(field, $event)" />
                        <b-form-input v-if="['text', 'number'].includes(field.field_type)" :id="`cfield-${index2}`" :value="getFieldResponse(field)" :placeholder="field.placeholder" @input="setFieldResponse(field, $event)" />
                        <v-select v-if="field.field_type === 'select'" :id="`cfield-${index2}`" :value="getFieldResponse(field)" :placeholder="field.placeholder" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="field.field_options_if_type_is_select" :clearable="field.required_field" @change="setFieldResponse(field, $event)" @input="setFieldResponse(field, $event)" />
                        <v-select v-if="field.field_type === 'trustees'" :id="`cfield-${index2}`" :value="getFieldResponse(field)" :placeholder="field.placeholder" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="dynamic_field_options.trustees" :clearable="field.required_field" :reduce="val => val.value" @change="setFieldResponse(field, $event)" @input="setFieldResponse(field, $event)" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-form>
                </validation-observer>
              </div>
            </b-card>
          </div>
        </b-col>

        <b-col cols="12" xl="4" md="4" class="invoice-actions mt-md-0 mt-2">
          <b-card-body class="pt-5 text-white bg-secondary ">
            <h3 class="font-weight-bold" style="color: #EAF2CE;">
              <strong>Summary: </strong>
            </h3>
          </b-card-body>

          <b-card-body class="pt-0 text-white bg-secondary">
            <b-row class="new-loan-request">
              <b-col>
                <div class="d-flex justify-content-between">
                  <p class="">
                    Processing Fee
                  </p>

                  <p class="">
                    {{ processingFee }}%
                  </p>
                </div>

                <div class="d-flex justify-content-between">
                  <p class="">
                    Payout
                  </p>

                  <p class="">
                    ¢{{ formatMoney(payout) }}
                  </p>
                </div>

                <div class="d-flex justify-content-between">
                  <p class="">
                    Interest Rate
                  </p>

                  <p class="">
                    {{ interestRate }}%
                  </p>
                </div>

                <div class="d-flex justify-content-between">
                  <p class="">
                    Interest
                  </p>

                  <p class="">
                    ¢{{ formatMoney(interest) }}
                  </p>
                </div>

                <div class="d-flex justify-content-between">
                  <p class="">
                    Monthly Payment
                  </p>

                  <p class="">
                    ¢{{ formatMoney(monthlyPayment) }}
                  </p>
                </div>

                <div class="d-flex justify-content-between" style="font-size: 18px; padding-bottom: 0;">
                  <h2 style="color: #ffffff">
                    Total Loan Cost
                  </h2>

                  <h2 style="color: #ffffff">
                    <strong>¢{{ formatMoney(totalLoanCost) }}</strong>
                  </h2>
                </div>

                <hr class="invoice-spacing">
                <div>
                  <validation-provider #default="{ errors }" name="Please agree to the terms before proceeding" rules="required|terms-and-conditions">
                    <b-form-checkbox id="register-privacy-policy" v-model="newLoan.agree_to_terms" name="checkbox">
                      <small style="color: white">
                        By submitting this form, I agree to the <b-link :to="{ name: 'terms' }" target="_blank" style="color: white; border-bottom: 1px solid white;">
                          terms and conditions</b-link>
                        of the loan and I acknowledge that my information will be used in
                        accordance with EXXTRA's <b-link :to="{ name: 'privacy' }" target="_blank" style="color: white; border-bottom: 1px solid white;">
                          privacy policy.</b-link>
                      </small>
                    </b-form-checkbox>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>

                  <div class="d-flex justify-content-start align-items-center">
                    <b-button class="my-2 bg-white text-secondary w-100" block type="submit" :disabled="!isLoansModuleEnabled" variant="light">
                      Apply
                    </b-button>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card-body>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</section>
</template>

<script>
import newLoanMixin from "@/pages/client/loans/new/NewLoanMixin";
import {
  ValidationProvider,
  ValidationObserver
} from 'vee-validate'

import {
  BOverlay,
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BCardText,
  BFormTextarea,
  BFormCheckbox,
  BFormRadio,
  BFormGroup,
  BPopover,
  VBToggle,
  BFormSpinbutton,
  BFormFile,
  BForm,
  BFormInput,
  BLink,
  BAlert,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'
import Ripple from 'vue-ripple-directive'
import VueSlider from 'vue-slider-component'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BOverlay,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BPopover,
    flatPickr,
    vSelect,
    BFormTextarea,
    BFormCheckbox,
    BFormRadio,
    BFormGroup,
    BFormSpinbutton,
    BFormFile,
    BForm,
    BFormInput,
    BLink,
    VueSlider,
    Cleave,
    BAlert,

    // validations
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  mixins: [newLoanMixin],
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  computed: {
    maxLoanAmount() {
      return this.product ? this.maxLoanAmountPerMonth * this.term : 0;
    },
  },
  watch: {
    amountInput: {
      handler() {
        this.updateLoanTerm()
      }
    },
    "newLoan.term_id": {
      handler() {
        const maxLoanForTerm = this.term * this.maxLoanAmountPerMonth;
        if (this.newLoan.amount > maxLoanForTerm) {
          this.newLoan.amount = maxLoanForTerm;
        }
      }
    },
  },
}
</script>
