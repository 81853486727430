import { get } from "lodash";
import { required } from "@core/utils/validations/validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

const newLoanMixin = {
  data() {
    return {
      loading: false,
      amountInput: "",
      errorMessage: "",
      showCleave: false,
      useOutputRule: false,
      outputPercentage: 0,
      answers: {},
      newLoan: {
        reason_for_loan: '',
        amount: 0,
        term_id: '',
        agree_to_terms: false,
      },
      // validation rules
      required,
      amountInputCleaveOptions: {
        numeral: true,
        numeralThousandsGroupStyle: 'thousand',
        onValueChanged: (e) => {
          const { rawValue: amount } = e.target;
          const client_group_minimum = this.getValueFromSource(this.product, 'meta.minimum_amount', 0);
          const client_group_maximum = this.useOutputRule ? this.outputPercentage : this.getValueFromSource(this.product, 'meta.maximum_amount', 0);

          const amountIsWithinUpperLimit = +amount <= client_group_maximum;
          const amountIsWithinLowerLimit = +amount >= client_group_minimum

          if (amountIsWithinUpperLimit && amountIsWithinLowerLimit) {
            this.newLoan.amount = +amount;
          }
        },
        rawValueTrimPrefix: true,
        prefix: "¢ "
      },
      amountSliderOptions: {
        interval: 1,
        tooltip: "none",
        dotSize: 30,
        height: 6,
        tooltipStyle: {
          "font-size": "12px"
        },
        dotStyle: {
          "background-color": "#144F6A",
          color: "#468F42",
          border: "none"
        },
        stepStyle: {
          color: "white",
          "background-color": "white",
          "border-radius": "0"
        }
      },
    }
  },
  computed: {
    settings() {
      return this.$store.getters[`auth/settings`];
    },
    isLoansModuleEnabled() {
      return this.getValueFromSource(this.settings, "loan_settings.enable_loan_module", false)
    },
    hideSliderLabel() {
      const breakPoint = this.$store.getters["app/currentBreakPoint"];
      return ["xs", "sm"].includes(breakPoint);
    },
    productId() {
      const { loan_product_id } = this.$route.params;
      return loan_product_id;
    },
    maxLoanAmountForThisProduct() {
      if (!this.product) return 0;
      return this.useOutputRule ? this.outputPercentage : this.getValueFromSource(this.product || {}, "meta.maximum_amount", 0)
    },
    minLoanAmountForThisProduct() {
      if (!this.product) return 0;
      return this.getValueFromSource(this.product || {}, "meta.minimum_amount", 0)
    },
    maxLoanAmountPerMonth() {
      if (!this.product) return 0;
      const {
        maximum_amount
      } = this.product.meta;

      return (this.useOutputRule ? this.outputPercentage : maximum_amount) / this.maxLoanTerm;
    },
    maxLoanTerm() {
      if (!this.product) return 10;

      const number_of_months = this.product.loan_terms
        .filter((term) => term.is_active)
        .map((term) => term.term_data.number_of_months)

      return Math.max(...number_of_months);
    },
    userData() {
      return this.$store.getters[`auth/userData`];
    },
    termData() {
      const { term_id } = this.newLoan;
      const term_data = this.getValueFromSource(this.product, 'loan_terms', []).find(term => term.term_id === term_id);
      return term_data || {}
    },
    term() {
      return this.getValueFromSource(this.termData, 'term_data.number_of_months', 0);
    },
    amount() {
      return parseFloat(this.newLoan.amount)
    },
    processingFee() {
      return this.getValueFromSource(this.product, 'meta.loan_processing_fee', 0);
    },
    payout() {
      return parseFloat(this.amount * (1 - (this.processingFee / 100)));
    },
    interestRate() {
      return this.getValueFromSource(this.product, 'meta.loan_monthly_rate', 0);
    },
    interest() {
      const calcRate = this.interestRate * this.term
      return parseFloat(this.amount * (calcRate / 100));
    },
    monthlyPayment() {
      const calc = (+this.interest + +this.amount) / this.term
      return parseFloat(calc)
    },
    totalLoanCost() {
      return parseFloat(+this.amount + +this.interest)
    },
    activeLoanTerms() {
      if (this.product && this.product.loan_terms) {
        return this.product.loan_terms.filter((term) => term.is_active)
      }
      return []
    },
    fields() {
      const activeFields = this.product.custom_fields.reduce((prev, cur) => {
        return [...prev, ...cur.fields]
      }, []).filter(field => field.status === "active" && !field.one_time_only);
      const sortRequiredFieldsFirst = activeFields.sort(field => !field.required_field);
      return sortRequiredFieldsFirst
    },
    customFieldsWithActiveQuestions() {
      return this.product.custom_fields.filter(
        custom_field => custom_field.fields.filter(field => field.status === "active" && !field.one_time_only).length > 0
      )
    },
    dynamic_field_options() {
      return this.getValueFromSource(
        this.product,
        "dynamic_field_options",
        { trustees: [] }
      )
    },
  },
  watch: {
    "newLoan.amount": {
      handler(amount) {
        this.amountInput = amount;
      },
      immediate: false
    },
    minLoanAmountForThisProduct: {
      handler(min) {
        if (+this.newLoan.amount < min) {
          this.newLoan.amount = min;
        }
      }
    },
    maxLoanAmountForThisProduct: {
      handler(max) {
        if (+this.newLoan.amount > max) {
          this.newLoan.amount = max;
        }
      }
    },
  },
  async created() {
    this.startup();
  },
  methods: {
    getAmountSliderMarks() {
      const marks = {
        [this.minLoanAmountForThisProduct]: `¢ ${this.minLoanAmountForThisProduct}`
      };

      this.activeLoanTerms.forEach(term => {
        const amount = this.maxLoanAmountPerMonth * term.term_data.number_of_months;
        const mark = Math.floor(amount);
        marks[mark] = `¢ ${this.formatMoney(amount, 0)}`;
      })

      return marks;
    },
    updateSliderOptions() {
      this.amountSliderOptions.marks = this.getAmountSliderMarks()
      this.amountSliderOptions.min = this.getValueFromSource(this.product, 'meta.minimum_amount', 0);
      this.amountSliderOptions.max = this.useOutputRule ? this.outputPercentage : this.getValueFromSource(this.product, 'meta.maximum_amount', 0);

      // update the slider value to the least loan amount
      this.newLoan.amount = this.getValueFromSource(this.product, 'meta.minimum_amount', 0);
    },
    increaseAmount() {
      const newAmount = +this.newLoan.amount + 1
      if (newAmount <= this.maxLoanAmountForThisProduct) {
        this.newLoan.amount = newAmount
        this.amountInput = newAmount;
      }
    },
    decreaseAmount() {
      const newLoanAmount = +this.newLoan.amount - 1;
      if (newLoanAmount >= +this.minLoanAmountForThisProduct) {
        this.newLoan.amount = newLoanAmount;
        this.amountInput = newLoanAmount
      }
    },
    getLoanTermMaxAmount(loan_term) {
      return Math.round(loan_term.term_data.number_of_months * this.maxLoanAmountPerMonth);
    },
    updateLoanTerm() {
      if (!this.product) {
        return
      }
      const sortedTerms = this.activeLoanTerms.sort((firstItem, secondItem) => {
        const {
          number_of_months: first
        } = firstItem.term_data.number_of_months;

        const {
          number_of_months: second
        } = secondItem.term_data.number_of_months;

        if (first === second) return 0;
        if (first > second) return 1;
        return -1;
      })

      const rawLoanAmount = this.newLoan.amount
      const amount = typeof rawLoanAmount === "string" ? parseInt(rawLoanAmount) : rawLoanAmount;

      if (amount > this.maxLoanAmount) {
        // eslint-disable-next-line no-restricted-syntax
        for (const loan_term of sortedTerms) {
          const maxAmountForTerm = this.maxLoanAmountPerMonth * loan_term.term_data.number_of_months;
          if (maxAmountForTerm >= amount) {
            this.newLoan.term_id = loan_term.term_id;
            return;
          }
        }
      }
    },
    startup() {
      this.updateSliderOptions();

      const loan_terms = this.getValueFromSource(this.product, 'loan_terms', [])
      if (loan_terms.length > 0) {
        this.newLoan.term_id = loan_terms[0].term_id;
      }
    },
    async fetchOutputPercentage() {
      try {
        this.loading = true;

        const response = await this.useJwt().clientService.loanProductOutputPercentage(this.productId);
        const { max_loan_amount } = response.data.data;

        this.outputPercentage = max_loan_amount;
        this.updateSliderOptions();
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error!",
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: error_message,
          },
        });
      } finally {
        this.loading = false;
      }
    },
    async requestLoan() {
      try {
        this.loading = true;
        this.errorMessage = '';

        const success = await this.$refs.applyLoanForm.validate();
        if (!success) {
          return;
        }

        if (this.$refs.customFieldsQuestionsForm) {
          const numberOfRefs = this.$refs.customFieldsQuestionsForm.length;
          const formRef = this.$refs.customFieldsQuestionsForm[numberOfRefs - 1];
          const isValid = await formRef.validate();
          if (!isValid) {
            return;
          }
        }

        const formData = new FormData();

        Object.keys(this.newLoan).forEach(field_name => {
          const form_value = this.newLoan[field_name];
          if (form_value) {
            formData.append(field_name, form_value);
          }
        })

        formData.append("client_group_id", this.productId)
        formData.append("custom_fields_answers", JSON.stringify(this.answers))
        await this.useJwt().clientService.requestLoan(formData);

        this.$router.replace({
          name: "client-loans"
        }).then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: "Your loan request is being processed. We'll update you shortly."
            },
          });
        });
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        this.errorMessage = error_message;

        const known_errors = {
          dashboard_profile_screenshot: [],
          e_zwich_payment_slip_screenshot: []
        };

        if (error_message.includes("dashboard profile")) {
          known_errors.dashboard_profile_screenshot.push(error_message)
        }

        if (error_message.includes("payment slip")) {
          known_errors.e_zwich_payment_slip_screenshot.push(error_message)
        }

        this.$refs.applyLoanForm.setErrors(known_errors);

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false
      }
    },
    setFieldResponse(field, value) {
      this.answers[field._id] = value;
    },
    getFieldResponse(field) {
      return this.answers[field._id];
    },
    setCustomFieldAttachment(field, value) {
      this.newLoan[field._id] = value;
    },
    getActiveFields(custom_field) {
      const { fields = [] } = custom_field;
      const activeFields = fields.filter((field) => field.status === "active" && !field.one_time_only);
      const sortRequiredFieldsFirst = activeFields.sort(field => !field.required_field);
      return sortRequiredFieldsFirst
    },
  },
}

export default newLoanMixin
